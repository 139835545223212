export function generateMaze(x, y) {
	// Establish variables and starting grid
	const totalCells = x * y;
	const maze = [];
	const unvisited = [];
	for (let i = 0; i < y; i++) {
		maze[i] = [];
		unvisited[i] = [];
		for (let j = 0; j < x; j++) {
			maze[i][j] = [0, 0, 0, 0];
			unvisited[i][j] = true;
		}
	}

	// Set a random position to start from
	let currentCell = [
		Math.floor(Math.random() * y),
		Math.floor(Math.random() * x),
	];
	const path = [currentCell];
	unvisited[currentCell[0]][currentCell[1]] = false;
	let visited = 1;

	// Loop through all available cell positions
	while (visited < totalCells) {
		// Determine neighboring cells
		const pot = [
			[currentCell[0] - 1, currentCell[1], 0, 2],
			[currentCell[0], currentCell[1] + 1, 1, 3],
			[currentCell[0] + 1, currentCell[1], 2, 0],
			[currentCell[0], currentCell[1] - 1, 3, 1],
		];
		const neighbors = [];

		// Determine if each neighboring cell is in game grid, and whether it has already been checked
		for (let l = 0; l < 4; l++) {
			if (
				pot[l][0] > -1 &&
				pot[l][0] < y &&
				pot[l][1] > -1 &&
				pot[l][1] < x &&
				unvisited[pot[l][0]][pot[l][1]]
			) {
				neighbors.push(pot[l]);
			}
		}

		// If at least one active neighboring cell has been found
		if (neighbors.length) {
			// Choose one of the neighbors at random
			const next = neighbors[Math.floor(Math.random() * neighbors.length)];

			// Remove the wall between the current cell and the chosen neighboring cell
			maze[currentCell[0]][currentCell[1]][next[2]] = 1;
			maze[next[0]][next[1]][next[3]] = 1;

			// Mark the neighbor as visited, and set it as the current cell
			unvisited[next[0]][next[1]] = false;
			visited++;
			currentCell = [next[0], next[1]];
			path.push(currentCell);
		}
		// Otherwise go back up a step and keep going
		else {
			currentCell = path.pop();
		}
	}
	return maze;
}
